.wrapper {
  display: flex;
  align-items: center;
  padding: calc(var(--mantine-spacing-xl) * 2);

  @media (max-width: 1200px) {
    flex-direction: column-reverse;
    padding: var(--mantine-spacing-xl);
  }
}

.image {
  max-width: 40%;

  @media (max-width: 1200px) {
    max-width: 40%;
  }
  @media (max-width: 800px) {
    display: none;
  }
}

.body {
  padding-right: calc(var(--mantine-spacing-xl) * 4);

  @media (max-width: 1200px) {
    padding-right: 0;
    margin-top: var(--mantine-spacing-xl);
  }
}

.title {
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-family:
    Greycliff CF,
    var(--mantine-font-family);
  line-height: 1;
  margin-bottom: var(--mantine-spacing-md);
}

.controls {
  display: flex;
  margin-top: var(--mantine-spacing-xl);
}

.inputWrapper {
  width: 100%;
  flex: 1;
}

.input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
